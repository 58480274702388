
import { useAppStore } from "@/stores/app";
import { defineComponent, ref } from "vue";
import CalculationDetail from "../components/CalculationDetail.vue";

export default defineComponent({
  name: "Calculation",
  components: {
    CalculationDetail
  },
  setup() {
    return {
      calculationExpanded: ref(false),
      calculationExpansionButtonHovered: ref(false),
      store: useAppStore(),
      prettyPrice: (value: number) => {
        if (value < 0) {
          value = value * -1;
        }
        return value
          .toFixed(2)
          .toString()
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      }
    };
  },
  computed: {
    buttonTextColor(): String {
      return this.calculationExpansionButtonHovered ? "F18700" : "FFFFFF";
    },
    gesamtrechnung() {
      let gesamtrechnung: any = {};
      this.store.object.newSituation.gesamtrechnung.forEach((element: any) => {
        gesamtrechnung[element.feld] = element.wert;
      });
      return gesamtrechnung;
    }
  },
  methods: {
    toggleCalculationExpansionButton() {
      this.calculationExpansionButtonHovered =
        !this.calculationExpansionButtonHovered;
    }
  }
});
