
import { defineComponent, ref } from "vue";
import ArrowUp from "../assets/ArrowUp.vue";
import ArrowDown from "../assets/ArrowDown.vue";
import { useAppStore } from "@/stores/app";

export default defineComponent({
  name: "CalculationDetail",
  components: {
    ArrowUp,
    ArrowDown
  },
  setup() {
    return {
      store: useAppStore(),
      calculationExpansionButtonHovered: ref(false),
      prettyPrice: (value: number) => {
        if (value == null) {
          value = 0;
        }
        if (value < 0) {
          value = value * -1;
        }
        return value
          .toFixed(2)
          .toString()
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      }
    };
  },
  computed: {
    buttonTextColor(): String {
      return this.calculationExpansionButtonHovered ? "F18700" : "FFFFFF";
    },
    vergleichsrechnung() {
      let vergleichsrechnung: any = {
        ohne: this.store.object.newSituation.vergleichsrechnung[0],
        mit: this.store.object.newSituation.vergleichsrechnung[3]
      };
      return vergleichsrechnung;
    },
    gesamtrechnung() {
      let gesamtrechnung: any = {};
      this.store.object.newSituation.gesamtrechnung.forEach((element: any) => {
        gesamtrechnung[element.feld] = element.wert;
      });
      return gesamtrechnung;
    }
  },
  methods: {
    toggleCalculationExpansionButton() {
      this.calculationExpansionButtonHovered =
        !this.calculationExpansionButtonHovered;
    }
  }
});
