
/* eslint-disable vue/require-prop-types */
import { useAppStore } from "@/stores/app";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Input",
  props: ["type", "field", "options", "defaultValue", "disabled"],
  emits: ["submitForm"],
  setup() {
    return {
      store: useAppStore()
    };
  },
  computed: {
    model: {
      get() {
        return this.store.form[this.field as any];
      },
      set(value: any) {
        this.store.form[this.field as any] = value;
      }
    },
    error() {
      let error = null;
      this.store.errors.forEach((element: any) => {
        if (element.context == this.field) {
          error = element;
        }
      });
      return error;
    }
  },
  watch: {
    error(value) {
      if (value != null) {
        this.store.app.formHasError = true;
      }
    }
  },
  mounted() {
    if (this.model == undefined) {
      this.model = null;
    }
  },
  beforeMount() {
    if (this.store.form[this.field as any] == undefined) {
      this.store.form[this.field as any] = this.defaultValue;
    }
  }
});
