
import { defineComponent } from "vue";
import Calculator from "./Calculator.vue";
import Calculation from "./Calculation.vue";
import Footer from "./Footer.vue";
import { useAppStore } from "../stores/app";

export default defineComponent({
  name: "Application",
  components: {
    Calculator,
    Calculation,
    Footer
  },
  setup() {
    return {
      store: useAppStore()
    };
  },
  beforeMount() {
    let configuration: any = JSON.parse(
      this.$route.params.configuration as any
    );
    this.store.config.jwtToken = configuration.jwtToken;
    if (configuration.displayFooter != undefined) {
      this.store.config.displayFooter = configuration.displayFooter;
    }
    if (configuration.displayCalculationDetail != undefined) {
      this.store.config.displayCalculationDetail = configuration.displayCalculationDetail;
    }
    this.store.initializeApp();
  },
  mounted() {
    const element: any = this.$refs.app;
    new ResizeObserver(this.appResized).observe(element);
  },
  methods: {
    appResized(entries: any) {
      let entry = entries[0];
      window.parent.postMessage(
        {
          type: "appResized",
          payload: {
            width: entry.contentRect.width,
            height: entry.contentRect.height
          }
        },
        "*"
      );
    }
  }
});
