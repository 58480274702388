<template>
  <svg
    height="48"
    viewBox="8 2 32 32"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z"
      :fill="'#' + color"
    />
    <path d="M0 0h48v48h-48z" fill="none" />
  </svg>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ArrowUp",
  props: {
    color: {
      type: String,
      default: "FFFFFF"
    }
  }
});
</script>
