import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42d1c08b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "app",
  class: "wrapper"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calculator = _resolveComponent("Calculator")!
  const _component_Calculation = _resolveComponent("Calculation")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.store.isAppInitialized)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Calculator),
          _createVNode(_component_Calculation),
          (_ctx.store.config.displayFooter)
            ? (_openBlock(), _createBlock(_component_Footer, { key: 0 }))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_v_progress_circular, {
            indeterminate: "",
            color: "black"
          })
        ]))
  ], 512))
}