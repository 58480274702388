import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "roboto-fontface";
import { createPinia } from "pinia";
import { createRouter, createWebHistory } from "vue-router";
import Application from "./ui/Application.vue";
import ApplicationUnconfigured from "./ui/ApplicationUnconfigured.vue";

loadFonts();

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    appResized: any;
  }
}

const routes = [
  { path: "/", component: ApplicationUnconfigured },
  { path: "/:configuration", component: Application }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

createApp(App).use(vuetify).use(createPinia()).use(router).mount("#app");
