import { defineStore } from "pinia";
import axios from "axios";

export const useAppStore = defineStore("app", {
  state: () => {
    const form: { [key: string]: any } = {};
    return {
      app: {
        initialized: false,
        formHasError: false,
        calculationExpanded: false,
        calculationPending: false
      },
      config: {
        jwtToken: "",
        displayFooter: true,
        displayCalculationDetail: true
      },
      form,
      object: null,
      errors: []
    };
  },
  actions: {
    async initializeApp() {
      // TODO: Implement Properties API call to make the application remotely configurable
      setTimeout(() => {
        this.app.initialized = true;
      }, 1);
    },
    async calculateObject() {
      this.app.calculationExpanded = false;
      this.app.calculationPending = true;
      this.app.formHasError = false;
      this.object = null;
      this.errors = [];
      axios
        .post(
          process.env.VUE_APP_API_URL + "calculateexternal",
          {
            ...this.form,
            ...{ fahrleistungGesamt: 2000, switchBeschaffungswegFahrrad: 0 }
          },
          {
            headers: {
              Authorization: "Bearer " + this.config.jwtToken,
              "Content-Type": "application/json"
            },
            withCredentials: false
          }
        )
        .then((response) => {
          this.object = response.data.data.objects[0];
          this.errors = response.data.errors;
          this.app.calculationPending = false;
        })
        .catch((error) => {
          console.log(error);
          this.app.calculationExpanded = false;
          this.app.calculationPending = true;
          this.object = null;
          this.errors = [];
        });
    }
  },
  getters: {
    isAppConfigured: (state) => state.config.jwtToken != undefined,
    isAppInitialized: (state) => state.app.initialized,
    isCalculationPending: (state) =>
      state.object == null && state.app.calculationPending,
    isCalculationReady: (state) =>
      state.object != null && !state.app.calculationPending
  }
});
