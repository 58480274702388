
import { useAppStore } from "@/stores/app";
import { defineComponent } from "vue";
import Input from "../components/Input.vue";

export default defineComponent({
  name: "Calculator",
  components: {
    Input
  },
  setup() {
    return {
      store: useAppStore()
    };
  },
  methods: {
    submitForm() {
      (document.activeElement as HTMLElement).blur();
      !this.store.isCalculationPending && this.store.calculateObject();
    }
  }
});
